"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

exports.__esModule = true;
exports.useQueries = useQueries;

var _react = _interopRequireDefault(require("react"));

var _index = require("use-sync-external-store/shim/index.js");

var _notifyManager = require("../core/notifyManager");

var _queriesObserver = require("../core/queriesObserver");

var _QueryClientProvider = require("./QueryClientProvider");

var _isRestoring = require("./isRestoring");

function useQueries({
  queries,
  context
}) {
  const queryClient = (0, _QueryClientProvider.useQueryClient)({
    context
  });
  const isRestoring = (0, _isRestoring.useIsRestoring)();

  const defaultedQueries = _react.default.useMemo(() => queries.map(options => {
    const defaultedOptions = queryClient.defaultQueryOptions(options); // Make sure the results are already in fetching state before subscribing or updating options

    defaultedOptions._optimisticResults = isRestoring ? 'isRestoring' : 'optimistic';
    return defaultedOptions;
  }), [queries, queryClient, isRestoring]);

  const [observer] = _react.default.useState(() => new _queriesObserver.QueriesObserver(queryClient, defaultedQueries));

  const result = observer.getOptimisticResult(defaultedQueries);
  (0, _index.useSyncExternalStore)(_react.default.useCallback(onStoreChange => isRestoring ? () => undefined : observer.subscribe(_notifyManager.notifyManager.batchCalls(onStoreChange)), [observer, isRestoring]), () => observer.getCurrentResult(), () => observer.getCurrentResult());

  _react.default.useEffect(() => {
    // Do not notify on updates because of changes in the options because
    // these changes should already be reflected in the optimistic result.
    observer.setQueries(defaultedQueries, {
      listeners: false
    });
  }, [defaultedQueries, observer]);

  return result;
}