import React, { useState } from 'react'
import { useContractWrite } from 'wagmi'
import { BigNumber } from 'ethers'
import { parseEther } from 'ethers/lib/utils'
import ABI from './ABI'
import Swal from 'sweetalert2'
import { contractAddress } from './const'

let lastHash: any = undefined

const MintView = () => {
    const [qty, setQty] = useState(1)

    const blur = () => {
        if(qty > 100 || qty < 1) {
            setQty(1)
        }
    }

    const { data: mintData, write: writeMint } = useContractWrite(
        {
            addressOrName: contractAddress,
            contractInterface: ABI,
        },
        'paidMint',
        {
            args: [BigNumber.from(qty == "" ? 0 : qty), {value: parseEther("0.005").mul(qty == "" ? 0 : qty)}]
        }
    )

    const mint = () => {
        writeMint()
    }

    if (mintData && mintData.hash !== lastHash) {
        lastHash = mintData.hash
        Swal.fire("Success", `Hatching <strong>${qty}</strong> ducks that should be visible on OpenSea and LooksRare shortly.`, 'success')
    }

    return(
        <div style={{backgroundColor:"rgba(255, 255, 255, 0.2)"}} className="w-11/12 lg:w-5/12 rounded-full p-10 my-2 mx-auto">
            <h3 className="text-4xl mt-2">Mint FOR 0.005 ETH</h3>

            <div className="my-4">
                <label className="text-xl">QUANTITY</label><br />

                <button disabled={qty < 2} className="bg-blue-500 hover:bg-blue-700 disabled:bg-gray-500 text-white text-2xl font-bold py-2 px-4 m-2 rounded-full inline-block" onClick={() => setQty(Number(qty)-1)}>
                    &lt;&lt;
                </button>

                <input
                    className="shadow text-2xl appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-600 inline-block" 
                    type="number" min="1" max="100" value={qty} onBlur={() => blur()} onChange={(e) => setQty(e.target.value)} />

                <button disabled={qty > 24} className="bg-blue-500 hover:bg-blue-700 disabled:bg-gray-500 text-white text-2xl font-bold py-2 px-4 m-2  rounded-full  inline-block" onClick={() => setQty(Number(qty)+1)}>
                    &gt;&gt;
                </button>
            </div>

            <button className="bg-blue-500 hover:bg-blue-700 disabled:bg-gray-500 text-white text-4xl font-bold py-4 px-6 m-2 rounded-full" onClick={mint}>
              MINT
            </button>
        </div>
    )
}

export default MintView