import React from 'react'

import { RainbowKitProvider, ConnectButton, configureChains, getDefaultWallets, apiProvider } from '@rainbow-me/rainbowkit'

import { WagmiProvider, chain, createClient } from 'wagmi'

import Heading from './components/Heading'
import DuckRow from './DuckRow'

import MintView from './MintView'

// Chains for connectors to support
const { chains, provider } = configureChains(
  [chain.mainnet],
  [
    apiProvider.alchemy("Hh8fdQZL2nYcS0fYVKslLvSoIZM7Ml9L"),
    apiProvider.fallback()
  ]
)

const { connectors } = getDefaultWallets({
  appName: 'Dastardly Moonducks',
  chains
})

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

/**
 * Top-level component for Dastardly Moonducks dApp
 */
const WebApp = () => {
    return (
        <WagmiProvider client={wagmiClient}>
            <RainbowKitProvider chains={chains} children={(
                <div className="m-24 text-center">
                    <Heading size={1}>Dastardly Moonducks</Heading>
                    <Heading size={2}>2,500 Pixel Art Ducks</Heading>

                    <DuckRow />

                    <div className="text-xl my-4 w-6/12 m-auto">
                    Official Dastardly Ducks Derivative. Owners of an original Dastardly Ducks before the project's launch on May 15th may claim one for free (gas costs only).
                    </div>

                    <div className="flex justify-center mt-8 mb-16">
                      <ConnectButton chainStatus="icon" accountStatus="address" />
                    </div>

                    <MintView />

                    <div className="mt-12">
                    <Heading size={2}>Links</Heading>
                    <a href="https://opensea.io/collection/dastardly-moonducks" className="hover:bg-blue-700 bg-blue-500 text-white text-6xl lg:text-4xl font-bold py-2 px-4 rounded-full">OpenSea</a>
                    <br /><br />
                    <a href="https://etherscan.io/address/0x8cfc5be7d45ee598e3ec20f7a9ef03f1e2886582#code" className="hover:bg-blue-700 bg-blue-500 text-white text-6xl lg:text-4xl font-bold py-2 px-4 rounded-full">Contract</a>
                    <br /><br />
                    <a href="https://dastardlyducks.com" className="hover:bg-blue-700 bg-blue-500 text-white text-6xl lg:text-4xl font-bold py-2 px-4 rounded-full">OG Ducks</a>
                    <br /><br />
                    <a href="https://twitter.com/dastardlyducks" className="hover:bg-blue-700 bg-blue-500 text-white text-6xl lg:text-4xl font-bold py-2 px-4 rounded-full">Twitter</a>
                    </div>
                </div>
            )}/>
        </WagmiProvider>
    )
}

export default WebApp