import React from 'react'

import duck0 from './duck0.png'
import duck1 from './duck1.png'
import duck2 from './duck2.png'
import duck3 from './duck3.png'

const DuckRow = () => {
    return(
        <div className="flex flex-row w-96 m-auto">
            <img alt="Moonduck 1" src={duck0} className="w-24" />
            <img alt="Moonduck 2" src={duck1} className="w-24" />
            <img alt="Moonduck 3" src={duck2} className="w-24" />
            <img alt="Moonduck 4" src={duck3} className="w-24" />
        </div>
    )
}

export default DuckRow