import React from 'react'
import { useAccount, useContractRead } from 'wagmi'
import { contractAddress } from './const'
import ABI from './ABI'

import FreeMintView from './FreeMintView'
import PaidMintView from './PaidMintView'

const MintView = () => {
    const { data: accountData } = useAccount()

    const { data: supplyData } = useContractRead(
        {
          addressOrName: contractAddress,
          contractInterface: ABI,
        },
        'totalSupply',
    )

    const { data: reserveData } = useContractRead(
        {
          addressOrName: contractAddress,
          contractInterface: ABI,
        },
        'freeDucksRemaining',
    )

    if(!accountData || !accountData?.address) {
        return <div className="text-lg">Connect wallet to see more.</div>
    }

    const minted = supplyData?.toString() ?? "0"
    const reserved = reserveData?.toString() ?? "0"

    return(
        <div>
            <h4 className="text-4xl mt-8 mb-2">{minted}/2500 HATCHED</h4> 

            <h5 className="text-3xl mb-4">{reserved} RESERVED FOR FREE MINTS</h5>

            <div className="flex flex-col lg:flex-row justify-center">
                <FreeMintView address={accountData?.address} />
                <PaidMintView />
            </div>
        </div>
    )
}

export default MintView