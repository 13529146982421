import React from 'react'

/**
 * Extremely basic React+Tailwind heading component
 */
const Heading = (props) => {
    const { size, children } = props

    switch(size) {
        case 1:
            return <h1 className="text-7xl my-3">{children}</h1>
        case 2:
            return <h2 className="text-5xl my-3">{children}</h2>
        default:
            return <h3 className="text-3xl my-3">{children}</h3>
    }
}

export default Heading